import React from "react";
import { BannerWhite, SupportLinks } from "./BannerWhite";
import { useLocation } from "@reach/router";

const WhiteBanner = () => {
  const location = useLocation();
  // const updateSource = location.pathname === "/";
  return (
    <BannerWhite>
      {/* The energy price cap will be £121 less per year for a typical household from 1 July.&nbsp; */}
      The energy price cap changed on 1 October.
      {/* {`We’re working through what this means for customers, and we’ll update ${updateSource} soon.`} */}
      &nbsp;
      {/* {location.pathname === "/" && ( */}
      <SupportLinks to="/pricecap/" state={{ isHolidaySeason: true }}>
        Find out how it impacts you
      </SupportLinks>
      {/* )} */}
    </BannerWhite>
  );
};
export default WhiteBanner;
