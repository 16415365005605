import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "@reach/router";
import { useSiteStaticData } from "./SiteMetadata";
import { withPrefix } from "gatsby";
import Navigation from "./Navigation";
import CookieBar from "./CookieBar";
import WebChatPromotion from "./WebChatPromotion";
import WhiteHomeBanner from "./WhiteHomeBanner";
import { configureGenesysMessengerNotifications } from "@ovotech/genesys-messenger-notifications";
import ChatRedirect from "./ChatRedirect-2";
const TemplateWrapper = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    const configureGenesysNotificationsHandler = () => {
      configureGenesysMessengerNotifications({
        serviceWorkerUrl: "../../genesys-messenger/sw.js",
        audioNotificationUrl: "../../genesys-messenger/notification.mp3",
        mixpanelToken: process.env.GATSBY_GENESYS_MIXPANEL_TOKEN,
      });
    };

    console.log("process.env.NODE_ENV: ", process.env.NODE_ENV);

    window.addEventListener("onGenesysLauncherReady", configureGenesysNotificationsHandler);

    /**
     * Unfortunately, converting the provided Genesys script to a modular setup, so that we can import it and provide
     * the necessary env variable (deploymentId) it needs, causes an internal widget error inside Genesys and the chat
     * stops working as expected. Dynamically requiring it and letting it self invoke itself in the script is the only
     * way I could avoid the aforementioned widget error, thus we end up with the below logic.
     */
    // process.env.NODE_ENV === "development"
    //   ? require("../../static/scripts/genesysChatDev")
    //   : require("../../static/scripts/genesysChatProd");


    return () =>
      window.removeEventListener("onGenesysLauncherReady", configureGenesysNotificationsHandler);
  }, []);

  const { title } = useSiteStaticData();
  const showBanner = location.pathname === "/";

  return (
    <div style={{ overflowX: "hidden" }} role="main">
      <Helmet>
        <html lang="en" />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${withPrefix("/")}img/apple-touch-icon.png`}
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix("/")}img/favicon-32x32.png`}
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix("/")}img/favicon-16x16.png`}
          sizes="16x16"
        />

        <link rel="mask-icon" href={`${withPrefix("/")}img/favicon-96x96.png`} color="#ff4400" />
        <meta name="theme-color" content="#fff" />

        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={title} />
        <meta property="og:url" content="/" />
        <meta property="og:image" content={`${withPrefix("/")}img/og-image.png`} />
      </Helmet>
      <Navigation />
      <ChatRedirect />
      {showBanner && <WhiteHomeBanner />}
      <div id="main-content">{children}</div>
      <WebChatPromotion />
      <CookieBar />
    </div>
  );
};

export default TemplateWrapper;
