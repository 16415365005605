import React, { useState } from "react";
import { usePopper } from "react-popper";
import ChatIcon from "../images/chat-icon.svg";
import ChevronDown from "../images/chevron-down.svg";
import { StyledLink } from "./Paragraph";

const ChatRedirect = () => {
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);
  const [toggler, setToggler] = useState(false);
  const { styles, attributes, state, forceUpdate } = usePopper(referenceElement, popperElement, {
    placement: "top-start",
    strategy: "fixed",
    modifiers: [
      { name: "arrow", options: { element: arrowElement } },
      {
        name: "offset",
        options: {
          offset: [0, 16],
        },
      },
    ],
  });

  const togglePopper = () => {
    setToggler((old) => !old);
    popperElement.style.display = !toggler ? "block" : "none";
    forceUpdate();
  };

  return (
    <>
      <button
        type="button"
        ref={setReferenceElement}
        onClick={togglePopper}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "fixed",
          bottom: "20px",
          right: "20px",
          borderRadius: "50%",
          width: "60px",
          height: "60px",
          backgroundColor: "rgb(178, 124, 2)",
          backgroundColor: "rgb(255, 178, 4)",
          boxShadow:
            "rgba(0, 0, 0, 0.2) 0px 3px 5px -2px, rgba(0, 0, 0, 0.14) 0px 1px 4px 2px, rgba(0, 0, 0, 0.12) 0px 1px 4px 1px",
          zIndex: "9999",
          color: "white",
          border: "none",
          cursor: "pointer",
        }}
      >
        {!toggler ? <ChatIcon style={{ width: "60%" }} /> : <ChevronDown style={{ width: "100%" }} />}
      </button>

      <div
        ref={setPopperElement}
        style={{
          display: "none",
          zIndex: "9999",
          maxWidth: "375px",
          backgroundColor: "white",
          padding: "1rem",
          boxShadow:
            "rgba(0, 0, 0, 0.2) 0px 3px 5px -2px, rgba(0, 0, 0, 0.14) 0px 1px 4px 2px, rgba(0, 0, 0, 0.12) 0px 1px 4px 1px",
          ...styles.popper,
        }}
        {...attributes.popper}
        data-show="true"
      >
        <p>
        We've recently moved all Boost customers to OVO. For any support you can now chat to them online here. They'll be happy to help you with all the same features that help you manage your account, top-up, and stay in control of your energy usage.
        </p>
        <StyledLink href="https://www.ovoenergy.com/help/category/pay-as-you-go">
          click here
        </StyledLink>
        <div
          ref={setArrowElement}
          style={{
            backgroundColor: "white",
            ...styles.arrow,
          }}
        />
      </div>
    </>
  );
};
export default ChatRedirect;
